import { Post } from '../request.js';
import { BASE_URL } from '../index.js';

function chat(params) {
  return Post(`${BASE_URL}/chat/stream`, params);
}

export const userApi = {
  chat,
};
