import { createBrowserRouter } from 'react-router-dom';
import Index from '../pages/Index';
import Chat from '../pages/chatUI';
import UserDetail from '../pages/UserDetail';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Index />,
  },
]);

export default router;
