import { useProgress } from '@react-three/drei';
import { useState, useEffect } from 'react';

function Loading(props) {
  const { onLoad } = props;
  const { progress } = useProgress();
  // console.log(progress);
  // const [progress, setProgress] = useState(0);

  useEffect(() => {
    // const timer = setInterval(() => {
    //   setProgress(progress => {
    //     if (progress + 1 >= 100) {
    //       onLoad();
    //       clearInterval(timer);
    //     }
    //     return progress + 1;
    //   });
    // }, 100);
    console.log(progress);
    if (progress >= 100) {
      // onLoad();
    }

    return () => {
      // clearInterval(timer);
    };
  }, [progress]);

  return (
    <>
      <div
        className="loading-container"
        style={progress >= 100 ? { opacity: 0, transition: 'opacity 3s ease 0s' } : null}>
        <div style={progress >= 100 ? { opacity: 0, transition: 'opacity 650ms ease 0s' } : null}>
          <span></span>
          <span></span>
          <span></span>
        </div>

        <p
          className="loader-counter"
          style={progress >= 100 ? { opacity: 0, transition: 'opacity 450ms ease 0s' } : null}>
          加载中 {Math.round(progress)} %
        </p>
      </div>
    </>
  );
}

export default Loading;
