import { BASE_URL } from '../api';

export const sleep = async delay => await new Promise(resolve => setTimeout(resolve, delay));

export function uuid() {
  const uuid = window.crypto.getRandomValues(new Uint8Array(8));
  return uuid.toString().split(',').join('');
}

export function debounce(fn, delay = 500) {
  let timer;
  return function () {
    if (timer) {
      clearTimeout(timer);
    }
    const args = arguments;
    timer = setTimeout(() => {
      fn.apply(this, args); // 改变this指向为调用debounce所指的对象
    }, delay);
  };
}

export const fetchKeywords = async chatHistory => {
  // const summaryKeywordsPrompt = `根据对话记录中给出的关键词,总结出3-5个关键词.以“、”分割, 比如: A、B、C、D、E`;
  const response = await fetch(`${BASE_URL}/tool/chat`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user_id: localStorage.getItem('user_id'),
      message: JSON.stringify(chatHistory),
      model: 'gpt-3.5-turbo-16k',
    }),
  });
  return response;
};

export const submitTask = async keywords => {
  const response = await fetch(`${BASE_URL}/tasks/run`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      task_type: 'search_keywords',
      user_id: localStorage.getItem('user_id'),
      metadata: {
        user_id: localStorage.getItem('user_id'),
        keywords: keywords.split('、'),
      },
    }),
  });
  const { task_id } = await response.json();
  return task_id;
};

export const fetchIntroduction = async summary => {
  const response = await fetch(`${BASE_URL}/tool/chat`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user_id: localStorage.getItem('user_id'),
      message: summary,
      model: 'gpt-3.5-turbo-16k',
    }),
  });
  return response;
};

export const handleStream = async (reader, callback, finishCallback) => {
  const decoder = new TextDecoder('utf-8');

  const handleStreamRecursively = async () => {
    const { done, value } = await reader.read();

    if (done) {
      finishCallback(value);
      return;
    }

    const dataStrings = decoder.decode(value).trim().split('data: ').filter(Boolean);

    dataStrings.forEach(data => {
      try {
        const parsedData = JSON.parse(data);
        callback(parsedData);
      } catch (error) {
        // console.error(error);
      }
    });

    await handleStreamRecursively();
  };

  await handleStreamRecursively();
};
