import React, { useRef, useMemo, useEffect } from 'react';
import * as THREE from 'three';
import { Environment, useTexture, OrbitControls, useGLTF } from '@react-three/drei';
import { extend, useThree, useLoader, useFrame } from '@react-three/fiber';
import { Water } from 'three-stdlib';
import TWEEN from '@tweenjs/tween.js';

extend({ Water });

function Ocean() {
  const ref = useRef();
  const gl = useThree(state => state.gl);
  const waterNormals = useLoader(THREE.TextureLoader, '/textures/water.jpeg');
  waterNormals.wrapS = waterNormals.wrapT = THREE.RepeatWrapping;
  const geom = useMemo(() => new THREE.PlaneGeometry(1000, 1000), []);
  const config = useMemo(
    () => ({
      textureWidth: 512,
      textureHeight: 512,
      waterNormals,
      sunDirection: new THREE.Vector3(),
      sunColor: 0xffffff,
      waterColor: 0x001e0f,
      distortionScale: 3.7,
      fog: false,
      format: gl.encoding,
    }),
    [waterNormals]
  );
  useFrame((state, delta) => (ref.current.material.uniforms.time.value += delta / 10));
  // return <water position-y={-2.4} ref={ref} args={[geom, config]} rotation-x={-Math.PI / 2} />;
    return null
}

let cameraStartLookAt = {
  x: 0,
  y: 3,
  z: -3,
};
let cameraStartPosition = {
  x: 0,
  y: 3,
  z: 0,
};
let cameraEndLookAt = {
  x: 0,
  y: 0,
  z: 0,
};
let cameraEndPosition = {
  x: 0,
  y: 0,
  z: 4,
};
let isInit = false;

function Stage(props) {
  const { loaded, showChatButton, chatMode } = props;
  const map = useTexture('/textures/anime_art_style_a_water_based_pokemon_like_environ.jpg');

  const scene = useGLTF('/models/scene.glb');
  scene.scene.traverse(mesh => {
    if (mesh.isMesh) {
      mesh.castShadow = true;
      mesh.receiveShadow = true;
    }
  });
  scene.castShadow = true;
  scene.receiveShadow = true;

  const { camera } = useThree();

  useEffect(() => {
    if (!isInit && loaded) {
      isInit = true;
      new TWEEN.Tween(camera.position)
        .to(cameraEndPosition, 4000)
        .easing(TWEEN.Easing.Quadratic.Out)
        .onUpdate(position => {
          camera.lookAt(0, position.y, position.z - 4);
        })
        .onComplete(() => {
          showChatButton();
        })
        .start();
    } else {
      camera.lookAt(0, cameraStartLookAt.y, cameraStartLookAt.z);
    }
  }, [loaded]);

  useEffect(() => {
    if (chatMode) {
      new TWEEN.Tween(camera.position)
        .to({ x: camera.position.x, y: camera.position.y, z: camera.position.z - 1.5 }, 1000)
        .easing(TWEEN.Easing.Quadratic.Out)
        .start();
    } else {
      new TWEEN.Tween(camera.position)
        .to({ x: camera.position.x, y: camera.position.y, z: camera.position.z + 1.5 }, 1000)
        .easing(TWEEN.Easing.Quadratic.Out)
        .start();
    }
  }, [chatMode]);

  useFrame(() => {
    TWEEN.update();
  });

  return (
    <>
      <directionalLight castShadow shadow-normalBias={0.04} position={[1, 2, 3]} intensity={1} />
      <ambientLight intensity={1} />
      <Environment files="/textures/venice_sunset_1k.hdr" />

      {/*<Ocean />*/}

      {/*<group>*/}
      {/*  <mesh scale={100}>*/}
      {/*    <sphereGeometry args={[5, 64, 64]} />*/}
      {/*    <meshStandardMaterial map={map} side={THREE.BackSide} />*/}
      {/*  </mesh>*/}

      {/*  /!*<primitive position-y={-1.2} object={scene.scene} />*!/*/}
      {/*</group>*/}

      <OrbitControls enableDamping minPolarAngle={0.75} maxPolarAngle={1.6} minDistance={3} maxDistance={6} />
    </>
  );
}

export default Stage;
