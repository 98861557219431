import { useRef, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SmallCoach from '../../components/SmallCoach';
import { debounce } from '../../utils';

function UserDetail(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const { detail } = location.state;
  console.log(detail);

  const listRef = useRef();
  // const containerRef = useRef();

  const [questions, setQuestions] = useState([]);
  const [activeQuestion, setActiveQuestion] = useState(null);

  // const [firstVisibleItem, setFirstVisibleItem] = useState(null);

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.9, // 当目标元素的50%可见时触发
  };

  useEffect(() => {
    if (listRef.current) {
      const observer = new IntersectionObserver(handleIntersect, options);
      const items = listRef.current.querySelectorAll('.node-wrapper');

      items.forEach(item => {
        observer.observe(item);
      });

      return () => {
        observer.disconnect();
      };
    }
  }, [listRef]);

  const handleIntersect = entries => {
    console.log(entries);
    setQuestions([]);
    entries.forEach((entry, index) => {
      if (entry.isIntersecting) {
        const item = entry.target;
        if (!item.dataset.timer) {
          const timer = setTimeout(() => {
            setQuestions(list => {
              const temp = [...list];
              const cur = JSON.parse(item.dataset.qa);
              cur.id = item.dataset.id;
              temp.push(cur);
              return temp;
            });
          }, 1000);
          item.dataset.timer = timer;
        }
      } else {
        const item = entry.target;
        if (item.dataset.timer) {
          clearTimeout(item.dataset.timer);
          delete item.dataset.timer;
        }
      }
    });
  };

  // useEffect(() => {
  //   console.log(2222);
  //   // containerRef..addEventListener('scroll', handleScroll);
  // }, []);

  // useEffect(() => {
  //   console.log(8888888, firstVisibleItem);
  // }, [firstVisibleItem]);

  // const handleScroll = debounce(e => {
  //   // 获取滚动位置
  //   const scrollY = e.target.scrollTop;
  //   // 获取视口高度
  //   const viewportHeight = window.innerHeight;

  //   let id = null;
  //   // 计算最上方的项
  //   let nextItemId = null;
  //   let nextItem = null;
  //   const current = detail.nodes.find((item, index) => {
  //     const itemElement = document.getElementById(`item-${index}`);
  //     if (itemElement) {
  //       const itemTop = itemElement.offsetTop;
  //       const itemBottom = itemTop + itemElement.clientHeight;
  //       const itemHeight = itemElement.clientHeight;

  //       // 判断80%的内容是否处于视口中
  //       const isItemInViewport = itemTop <= scrollY + 0.8 * viewportHeight && itemBottom >= scrollY;

  //       if (isItemInViewport) {
  //         id = index;
  //         return true;
  //       } else {
  //         nextItemId = index + 1;
  //         nextItem = detail.nodes[index + 1];
  //       }
  //     }
  //     return false;
  //   });
  //   console.log(current);
  //   setFirstVisibleItem(current);

  //   const timer = setTimeout(() => {
  //     console.log(firstVisibleItem);
  //     if (!firstVisibleItem) {
  //       clearTimeout(timer);
  //       return;
  //     }
  //     console.log(current);
  //     if (current && current.content == firstVisibleItem.content) {
  //       setActiveQuestion(id);
  //     } else if (nextItem && nextItem.content == firstVisibleItem.content) {
  //       setActiveQuestion(nextItemId);
  //     } else {
  //       clearTimeout(timer);
  //     }
  //   }, 3000);
  // });

  return (
    <div className={`detail-page`}>
      <SmallCoach questions={questions} setActiveQuestion={setActiveQuestion} setQuestions={setQuestions} />

      <header className="user-detail-header">
        <div className="user-detail-header-left">
          <div className="go-back-button" onClick={() => navigate('/')}>
            <svg width="11" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.20337 18.5695C8.91978 18.5695 8.63833 18.4621 8.42134 18.2429L0.323877 10.0746C-0.107959 9.64058 -0.107959 8.93374 0.323877 8.49976L8.42134 0.3271C8.85317 -0.109033 9.55142 -0.109033 9.98325 0.3271C10.4151 0.761084 10.4151 1.46792 9.98325 1.9019L2.66782 9.28608L9.98325 16.6681C10.4151 17.1042 10.4151 17.8089 9.98325 18.2429C9.76841 18.4599 9.48481 18.5695 9.20337 18.5695Z"
                fill="#3B3777"
              />
            </svg>
          </div>
          <span>Coach</span>
        </div>
        <div className="go-chat-button" onClick={() => navigate('/chat')}>
          <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.9091 1.63091C16.02 1.63091 20.1818 4.81091 20.1818 8.72182C20.1818 12.6327 16.02 15.8127 10.9091 15.8127C10.503 15.8227 10.102 15.9058 9.72545 16.0582C8.36343 16.6777 7.08138 17.4597 5.90727 18.3873C5.80364 15.3818 5.36182 14.4273 4.59273 13.8982C2.72727 12.5618 1.63636 10.6745 1.63636 8.72727C1.63636 4.81091 5.79818 1.63636 10.9091 1.63636M10.9091 0C4.90909 0 0 3.90545 0 8.72727C0 11.3127 1.40182 13.6364 3.63818 15.2345C4.18364 15.6273 4.28727 17.9618 4.28727 19.74C4.28511 19.8668 4.30794 19.9928 4.35446 20.1107C4.40099 20.2287 4.4703 20.3363 4.55844 20.4275C4.64657 20.5187 4.7518 20.5916 4.86812 20.6421C4.98444 20.6926 5.10957 20.7197 5.23636 20.7218C5.43497 20.714 5.62597 20.6434 5.78182 20.52C7.15091 19.4618 9.10364 18.0491 10.2818 17.6018C10.4802 17.5159 10.693 17.4678 10.9091 17.46C16.9091 17.46 21.8182 13.5491 21.8182 8.73273C21.8182 3.91636 16.9091 0.00545454 10.9091 0.00545454V0Z"
              fill="#3B3777"
            />
            <path
              d="M14.4545 6.50728H7.36359C7.1466 6.50728 6.93849 6.59348 6.78505 6.74692C6.63161 6.90036 6.54541 7.10846 6.54541 7.32546C6.54541 7.54246 6.63161 7.75056 6.78505 7.904C6.93849 8.05744 7.1466 8.14364 7.36359 8.14364H14.4545C14.6715 8.14364 14.8796 8.05744 15.033 7.904C15.1865 7.75056 15.2727 7.54246 15.2727 7.32546C15.2727 7.10846 15.1865 6.90036 15.033 6.74692C14.8796 6.59348 14.6715 6.50728 14.4545 6.50728ZM13.3636 10.3255H8.4545C8.23751 10.3255 8.0294 10.4117 7.87596 10.5651C7.72252 10.7185 7.63632 10.9266 7.63632 11.1436C7.63632 11.3606 7.72252 11.5687 7.87596 11.7222C8.0294 11.8756 8.23751 11.9618 8.4545 11.9618H13.3636C13.5806 11.9618 13.7887 11.8756 13.9421 11.7222C14.0956 11.5687 14.1818 11.3606 14.1818 11.1436C14.1818 10.9266 14.0956 10.7185 13.9421 10.5651C13.7887 10.4117 13.5806 10.3255 13.3636 10.3255Z"
              fill="#3B3777"
            />
          </svg>
        </div>
      </header>

      <div className="user-info-container">
        <div className="info-wrapper">
          <div className="user-avatar">
            <img src={`${detail.avatar}`} />

            <div className="user-sex-age-container">
              <div className="user-sex">
                {detail.gender && detail.gender.length ? (
                  detail.gender === 'FEMALE' ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                      <path
                        d="M5.62479 0C3.21256 0 1.24957 1.9627 1.24957 4.37457C1.24957 5.44576 1.63729 6.42906 2.27892 7.18973L1.7496 7.71897L0.89018 6.85968C0.743689 6.71321 0.506372 6.71321 0.359881 6.85968C0.213389 7.00615 0.213389 7.24343 0.359881 7.3899L1.22027 8.2492L0.109869 9.35944C-0.0366229 9.50591 -0.0366229 9.74319 0.109869 9.88966C0.183114 9.96289 0.278822 10 0.375507 10C0.471214 10 0.566922 9.96289 0.640168 9.88966L1.75057 8.77942L2.60999 9.63871C2.68324 9.71194 2.77894 9.74905 2.87563 9.74905C2.97134 9.74905 3.06704 9.71194 3.14029 9.63871C3.28678 9.49224 3.28678 9.25496 3.14029 9.10849L2.28087 8.2492L2.8102 7.71995C3.57 8.36149 4.55344 8.74915 5.62479 8.74915C8.03701 8.74915 10 6.78645 10 4.37457C10 1.9627 8.03701 0 5.62479 0ZM5.62479 7.99922C3.62567 7.99922 1.99961 6.3734 1.99961 4.37457C1.99961 2.37574 3.62567 0.749927 5.62479 0.749927C7.62391 0.749927 9.24996 2.37574 9.24996 4.37457C9.24996 6.3734 7.62391 7.99922 5.62479 7.99922Z"
                        fill="#F1497A"
                      />
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                      <g clip-path="url(#clip0_146_268)">
                        <path
                          d="M9.23047 0H7.30762C7.0957 0 6.92285 0.171875 6.92285 0.384766C6.92285 0.597656 7.09473 0.769531 7.30762 0.769531H8.68652L7.375 2.08105C6.59375 1.42285 5.58594 1.02539 4.4873 1.02539C2.0127 1.02539 0 3.03906 0 5.5127C0 7.98633 2.0127 10 4.4873 10C6.96191 10 8.97461 7.9873 8.97461 5.5127C8.97461 4.41406 8.57715 3.40625 7.91895 2.625L9.23047 1.31348V2.69238C9.23047 2.90527 9.40234 3.07715 9.61523 3.07715C9.82812 3.07715 10 2.90527 10 2.69238V0.769531C10 0.344727 9.65527 0 9.23047 0ZM4.4873 9.23047C2.4375 9.23047 0.769531 7.5625 0.769531 5.5127C0.769531 3.46289 2.4375 1.79492 4.4873 1.79492C6.53711 1.79492 8.20508 3.46289 8.20508 5.5127C8.20508 7.5625 6.53711 9.23047 4.4873 9.23047Z"
                          fill="#3B6CEC"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_146_268">
                          <rect width="10" height="10" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  )
                ) : null}
              </div>
              <div className="user-age">{detail.age}</div>
            </div>
            <div className="user-name">{detail.screenName}</div>
          </div>
          <div className="user-bio">{detail.bio}</div>
          <div className="user-tags-container">
            {detail.tags &&
              detail.tags.length > 0 &&
              detail.tags
                .filter(item => item.type === 'NORMAL')
                .map(item => <span className="user-tag">{item.text}</span>)}
          </div>
          <div className="user-description">{detail.summary}</div>
        </div>
      </div>

      <div ref={listRef}>
        {detail.nodes &&
          detail.nodes.length > 0 &&
          detail.nodes.map((item, index) => (
            <div
              className="node-container"
              onClick={() => {
                setQuestions(list => {
                  console.log(1111111, list);
                  const temp = [...list];
                  const a = item.qa;
                  a.id = index;
                  temp.unshift(a);
                  return temp;
                });
                // console.log(item);
                // setActiveQuestion(index);
              }}>
              <div
                id={`item-${index}`}
                className={`node-wrapper ${activeQuestion == index ? 'active' : null}`}
                data-qa={JSON.stringify(item.qa)}
                data-id={index}>
                {item.pictures && item.pictures.length > 0 && <img src={item.pictures[0].smallPicUrl} />}
                <div>{item.content}</div>
              </div>
            </div>
          ))}
        <div style={{ height: '360px' }}></div>
      </div>
    </div>
  );
}

export default UserDetail;
