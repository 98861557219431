import React, { useEffect, useState } from 'react';
import { useGLTF, useAnimations, PresentationControls } from '@react-three/drei';
import { sleep } from '../../utils';

export default function Coach(props) {
  const { loaded, currentAction } = props;

  const coach = useGLTF('/models/27.glb');
  coach.scene.traverse((mesh, i) => {
    if (mesh.isMesh) {
      mesh.castShadow = true;
      mesh.receiveShadow = true;
    }
  });
  coach.castShadow = true;
  coach.receiveShadow = true;

  const animations = useAnimations(coach.animations, coach.scene);

  console.log(animations);

  const [currentAnimationName, setCurrentAnimationName] = useState('挥手');

  useEffect(() => {
    if (currentAction) {
      console.log('有指定动作', currentAction);
      setCurrentAnimationName(currentAction);
    } else {
      console.log('没有指定动作', currentAction);
      startAnimation();
    }
  }, [currentAction]);

  const startAnimation = async () => {
    setCurrentAnimationName('挥手');
    await sleep(3000);
    setCurrentAnimationName('站立');
    await sleep(3000);
    setCurrentAnimationName('抱胸');
    await sleep(8000);
    setCurrentAnimationName('站立');
  };

  useEffect(() => {
    const action = animations.actions[currentAnimationName];
    if (currentAnimationName === '站立') {
      action.timeScale = 0.3;
    }
    action && action.reset().fadeIn(0.3).play();

    return () => {
      action && action.fadeOut(0.3);
    };
  }, [currentAnimationName]);

  return (
    <PresentationControls
      scale={100}
      config={{ mass: 2, tension: 500 }}
      snap={{ mass: 4, tension: 1500 }}
      rotation={[0, 0.3, 0]}
      polar={[0, 0]}
      azimuth={[-Math.PI / 1.4, Math.PI / 2]}>
      <primitive position-y={-1.2} object={coach.scene} />
    </PresentationControls>
  );
}

useGLTF.preload('/models/27.glb');
