import { useState, useEffect, useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import { EffectComposer, Bloom } from '@react-three/postprocessing';
import Coach from '../../components/Coach';
import Stage from '../../components/Stage';
import Loading from '../../components/Loading';
import { sleep, uuid, handleStream, fetchKeywords, submitTask, fetchIntroduction } from '../../utils';
// import { riseInput } from './utils/riseinput';
// import { useOutsideClick } from './utils/use-outside-click';
// import { api } from './api';
// import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../api';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Dictaphone from "../../components/Call";

// import vConsole from 'vconsole';

// setTimeout(() => {
//   new vConsole();
// }, 5000);

const normalPrompt = '作为一个社交需求分析师,根据给出的关键词,生成1个追问的问题.来进一步确认用户的意图.';
// const prompt2 = '根据给出的关键词,总结出2-8个关键词.';
const questionListMap = {
  1: [
  "你（鲁宾逊）是如何漂流到荒岛上的？",
  "在荒岛上，你（鲁宾逊）是如何生存的？",
  "你（鲁宾逊）是如何建造自己的住所和工具的？",
  "你（鲁宾逊）如何与岛上的动物相处？",
  "你（鲁宾逊）是如何保持乐观和坚持不懈的？",
  "你（鲁宾逊）最终是如何离开荒岛的？",
  "你（鲁宾逊）在漂流记中有什么重要的成长和变化？",
  "你认为你（鲁宾逊）给人们带来了什么启示或教训？"
],

  2:  [
"谁是你（皮埃尔·阿龙纳斯）教授的同伴？",
"潜水艇鹦鹉螺号是由谁建造的？",
"为什么尼莫船长建造了鹦鹉螺号？",
"鹦鹉螺号是如何驱动的？",
"主人公们在潜水艇中进行了哪些海底探险？",
"鹦鹉螺号上发生了什么意外？",
"为什么尼莫船长对阿龙纳斯忽然变脸？",
"鹦鹉螺号遭到了什么样的攻击？",
"尼莫船长对战舰的撞击造成了什么后果？",
"主人公们最终逃脱了吗？"
],
3: [
"你（保尔·柯察金）是哪个国家的人？",
"你（保尔·柯察金）的家庭背景如何？",
"你（保尔·柯察金）在哪个工厂工作？",
"你（保尔·柯察金）为什么被从学校开除？",
"你（保尔·柯察金）是如何受伤的？",
"你（保尔·柯察金）和冬妮娅为什么分手？",
"你（保尔·柯察金）后来重新回到了什么领域？"
],
4:[
"你（保尔·柯察金为什么斩断了和丽达的情丝？",
"谁指挥平定了舍佩托夫卡的暴乱？",
"为什么城里没有木材作燃料过冬？",
"你（保尔·柯察金）在索契为什么受伤？",
"你（保尔·柯察金）的身体状况如何？",
"你（保尔·柯察金）写了一部什么样的小说？",
"你（保尔·柯察金）的小说是否获得了赞赏并即将出版？",
"你（保尔·柯察金）开始了什么样的新生活？"
],
5: [
    '为什么许多鲨鱼会来抢夺你（圣地亚哥）的战利品？',
    '你（圣地亚哥）是如何杀死那些鲨鱼的？',
    '马诺林是谁？他和你（圣地亚哥）有什么关系？',
    '你（圣地亚哥）和马诺林之间的友谊如何？'
]
}

const bookMap = {
  1: '鲁宾逊漂流记',
  2: '海底两万里',
  3: '钢铁是这样炼成的 第一部',
  4: '钢铁是这样炼成的 第二部',
  5: '老人与海'
}

const colors = [
  "#660066",
  "#8B008B",
  "#9932CC",
  "#8A2BE2",
  "#9400D3",
  "#800080",
  "#4B0082",
  "#6A0DAD",
  "#800000",
  "#8B0000",
  "#A52A2A",
  "#800000",
  "#9932CC",
  "#800080",
  "#8B008B",
  "#8A2BE2",
  "#9400D3",
  "#4B0082",
  "#6A0DAD",
  "#8B0000"
]

function App() {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [showChatButton, setShowChatButton] = useState(false);
  const [showRecordsButton, setShowRecordsButton] = useState(false);
  const [showInputArea, setShowInputArea] = useState(true);
  const [showRecords, setShowRecords] = useState(false);

  const [chatMode, setChatMode] = useState(true);

  const textareaContainerRef = useRef();

  const [userInput, setUserInput] = useState('');
  const [agentOutput, setAgentOutput] = useState(null);

  const [currentAction, setCurrentAction] = useState('站立');
  const [currentBook, setCurrentBook] = useState(1);

  const [isFirstAnswer, setIsFirstAnswer] = useState(true);

  const [taskResult, setTaskResult] = useState(null);

  const [waitingMessage, setWaitingMessage] = useState(null);

  const [chatHistory, setChatHistory] = useState([
    { role: 'system', content: '根据对话记录中给出的关键词,总结出2-8个关键词.以“、”分割,比如: A、B、C、D、E' },
  ]);

  const [waitingMessageProgress, setWaitingMessageProgress] = useState('1%');

  const [isFetching, setIsFetching] = useState(false);

  // useOutsideClick({
  //   ref: textareaContainerRef,
  //   handler: async function () {
  //     setChatMode(false);
  //     setShowInputArea(false);
  //     await sleep(300);
  //     setShowChatButton(true);
  //     setShowRecordsButton(true);
  //   },
  // });

  useEffect(() => {
    generateUserUuid();
  }, []);

  const generateUserUuid = async () => {
    // const rr = await fetch('https://wfyos.com:5001/', {
    //   method: 'get',
    // });
    // console.log(rr);
    let userId = localStorage.getItem('user_id');
    if (!userId) {
      userId = uuid();
      localStorage.setItem('user_id', userId);
    } else {
      // 如果已经有 user id，看看是否有 task id
      let task_id = localStorage.getItem('task_id');
      if (task_id) {
        queryTaskState(task_id);
      }
    }
  };

  useEffect(() => {
    const $root = document.getElementById('root');
    window.addEventListener('scroll', function () {
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      $root.style.top = scrollTop + 'px';
      if (textareaContainerRef.current) {
        // this.setTimeout(() => {
        textareaContainerRef.current.style.bottom = scrollTop + 'px';
        // }, 200);
      }
    });
  }, [textareaContainerRef]);

  const handleSend = async (message) => {
    if (isFetching) return;
    setIsFetching(true);
    setCurrentAction('思考');
    sendFirstAnswer(message);
  };

  const sendFirstAnswer = async (message) => {
    // setChatHistory(history => {
    //   const temp = [...history];
    //   temp.push({ role: 'user', content: userInput });
    //   return temp;
    // });
    try {
      setAgentOutput('思考中。。。');
      const response = await fetch(`${BASE_URL}/book/chat/stream`, {
        method: 'POST',
        redirect: 'follow',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'text/event-stream',
        },
        body: JSON.stringify({
          user_id: localStorage.getItem('user_id'),
          message: `${message}`,
          book_id: currentBook,
        }),
      });
      const reader = response.body.getReader();
      await handleStream(
        reader,
        data => {
          setCurrentAction('对话');
          setAgentOutput(data);
        },
        function (data) {
          setCurrentAction('抱胸');
          // setChatHistory(history => {
            // const temp = [...history];
            // temp.push({ role: 'assistant', content: data });
            // return temp;
          // });
          setIsFetching(false);
        }
      );
      setUserInput('');
    }catch (e) {
      setAgentOutput("出错了。请尝试重新加载")
      setCurrentAction('抱胸');
      setIsFetching(false);
    }
  };

  const fetchQuestion = async (isReplace = false) => {
    if (isFetching) return;
    setIsFetching(true);
    if (!isReplace) {
      setChatHistory(history => {
        const temp = [...history];
        temp.push({ role: 'user', content: userInput });
        return temp;
      });
    }

    const response = await fetch(`${BASE_URL}/tool/chat`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id: localStorage.getItem('user_id'),
        message: isReplace
          ? '换一个'
          : `
              ${normalPrompt}
              关键词: ${userInput}
            `,
        model: 'gpt-3.5-turbo-16k',
      }),
    });
    const jsonResponse = await response.json();
    setAgentOutput(jsonResponse);
    setUserInput('');
    setIsFetching(false);
  };

  const runTask = async () => {
    if (isFetching) return;
    setIsFetching(true);
    setCurrentAction('思考');
    const r = await fetchKeywords(chatHistory);
    let res = await r.json();
    const task_id = await submitTask(res);
    if (task_id) {
      localStorage.setItem('task_id', task_id);
      setChatMode(false);
      setShowInputArea(false);
      queryTaskState(task_id);
    }
  };

  const queryTaskState = async id => {
    const response = await fetch(`${BASE_URL}/tasks/<task_id>?task_id=${id}`, {
      method: 'get',
      headers: { 'Content-Type': 'application/json' },
    });
    const jsonResponse = await response.json();
    console.log(new Date(), jsonResponse);
    switch (jsonResponse.task_status) {
      case 'SUCCESS':
        // fetchIntroduction(``);
        setWaitingMessage('找到人啦，我对他们进行了详细分析，快去看看吧～');
        setTaskResult(jsonResponse.task_result.users);
        setWaitingMessageProgress(null);
        setCurrentAction('想到点子');
        await sleep(5000);
        setCurrentAction('抱胸');
        setShowChatButton(true);
        setIsFetching(false);
        setTimeout(() => {
          setWaitingMessage(null);
        }, 3000);
        break;
      case 'PROGRESS':
        setCurrentAction('思考');
        setWaitingMessage(
          `
          ${jsonResponse.task_result.message}（${
            Math.round(jsonResponse.task_result.extra.step_percent * 100) == 100
              ? '99'
              : Math.round(jsonResponse.task_result.extra.step_percent * 100)
          }%）
          `
        );
        setWaitingMessageProgress(`${Math.round(jsonResponse.task_result.extra.step_percent * 100)}%`);
        await sleep(2000);
        queryTaskState(id);
        break;
      case 'FAILURE':
        setWaitingMessage(null);
        break;
      default:
        setWaitingMessage('......');
        await sleep(2000);
        queryTaskState(id);
        break;
    }
  };

  return (
    <>
 {/*<select*/}
 {/*       style={{ position: 'absolute', top: '20px', left: '20px', zIndex: 2 }}*/}
 {/*       onChange={e => setCurrentAction(e.target.value)}>*/}
 {/*       {[*/}
 {/*         '为难',*/}
 {/*         '伤心',*/}
 {/*         '兴奋跳起',*/}
 {/*         '双臂举起舒展放松',*/}
 {/*         '对话',*/}
 {/*         '小声说话',*/}
 {/*         '思考',*/}
 {/*         '想到点子',*/}
 {/*         '抱胸',*/}
 {/*         '拒绝',*/}
 {/*         '指向上',*/}
 {/*         '指向下',*/}
 {/*         '指向右',*/}
 {/*         '指向左',*/}
 {/*         '挥手',*/}
 {/*         '握拳放胸前',*/}
 {/*         '步行',*/}
 {/*         '烦恼',*/}
 {/*         '烦恼02',*/}
 {/*         '站立',*/}
 {/*       ].map(item => (*/}
 {/*         <option>{item}</option>*/}
 {/*       ))}*/}
 {/*     </select>*/}

      {taskResult && taskResult.length > 0 && (
        <div className="user-by-search-container">
          <div className="user-by-search-wrapper">
            {taskResult.map(item => (
              <span
                className="user-by-search-user"
                onClick={() => {
                  navigate('/detail', { state: { detail: item } });
                }}>
                <img src={item.avatar} />
              </span>
            ))}
          </div>
        </div>
      )}

     {/*<button*/}
     {/*   className={`recordsButton ${showRecordsButton ? 'entering' : 'leaving'}`}*/}
     {/*   onClick={async () => {*/}
     {/*     await sleep(500);*/}
     {/*     setShowChatButton(false);*/}
     {/*     setShowRecordsButton(false);*/}
     {/*     await sleep(300);*/}
     {/*     setShowRecords(true);*/}
     {/*   }}>*/}
     {/*   <img className="buttonIcon" src="/icon/recordsButton.svg" />*/}
     {/*   <img className="buttonBackground" src="/icon/mainButtonBackground.svg" />*/}
     {/* </button>*/}
     {/* <button*/}
     {/*   className={`chatButton ${showChatButton ? 'entering' : 'leaving'}`}*/}
     {/*   onClick={async () => {*/}
     {/*     await sleep(500);*/}
     {/*     setShowChatButton(false);*/}
     {/*     setShowRecordsButton(false);*/}
     {/*     await sleep(300);*/}
     {/*     setShowInputArea(true);*/}
     {/*     setChatMode(true);*/}
     {/*     setWaitingMessage('你想寻找什么样的人？');*/}
     {/*     setWaitingMessageProgress(null);*/}
     {/*     setTimeout(() => {*/}
     {/*       setWaitingMessage(null);*/}
     {/*     }, 3000);*/}
     {/*   }}>*/}
     {/*   <img className="buttonIcon" src="/icon/chatButton.svg" />*/}
     {/*   <img className="buttonBackground" src="/icon/mainButtonBackground.svg" />*/}
     {/* </button>*/}

     {/* <Dictaphone/>*/}

        <div style={{zIndex:12, position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', justifyItems: 'center'}}>
         <center> <h1>{bookMap[currentBook]}</h1>
          <select style={{ position: 'absolute', top: '72px', left: 0, right: 0, width: '160px',
            marginLeft: 'auto', marginRight: 'auto',zIndex: 2 }}
                  onChange={e => setCurrentBook(e.target.value)}>
            {Object.keys(bookMap).map((key)=>(
              <option value={key}>{bookMap[key]}</option>
            ))}
          </select>
         </center>
        </div>

      <div ref={textareaContainerRef} className={`input-container ${showInputArea ? 'entering' : 'leaving'}`}>
        <div className="input-wrapper">
          <textarea onInput={e => setUserInput(e.target.value)} value={userInput} placeholder="请输入你的问题" />
            <button className="sendButton" onClick={async ()=>{
              await handleSend(userInput)
            }}>
                <span className="shadow"/>
                <span className="edge"/>
                <span className="front">发送</span>
            </button>
        </div>
      </div>

        <center>
                 <div className={'question-layout-container'}>
                     <div className={'question-container'}>
                         {questionListMap[currentBook]?.map((q)=>{
                             return (
                                     <button onClick={async ()=>{
                                      await handleSend(q)
                                    }} className='question-btn' style={{backgroundColor: colors[currentBook]}}>
                                         {q}
                                </button>
                             )
                         })}
                     </div>
        </div>
        </center>

      <div className={`records-container ${showRecords ? 'entering' : 'leaving'}`}>
        <button
          className="closeButton"
          onClick={async () => {
            await sleep(500);
            setShowRecords(false);
            await sleep(300);
            setShowChatButton(true);
            await sleep(500);
            setShowRecordsButton(true);
          }}>
          <img src="/icon/closeButton.svg" />
        </button>
        <div className="records-wrapper">
          {[1, 1, 1, 11, 1, 1, 1, 11, 1, 1, 11, 1, 11].map(() => (
            <div className="record">
              <span className="record-icon"></span>
              <span className="record-content">All the best drivers are competing in a car rece on Cove Island...</span>
            </div>
          ))}
        </div>
      </div>

      {chatMode && agentOutput && (
        <div className="coach-message-container">
          <div className="coach-message">
            <div className="agentOutput">{agentOutput}</div>
            <img className="coach-message-top-icon" src="/icon/coachMessageTop.svg" />
            <img className="coach-message-bottom-icon" src="/icon/coachMessageBottom.svg" />

            <div className="coach-message-button-group">
              {/*<button onClick={() => fetchQuestion(true)}>*/}
              {/*  <span class="edge"></span>*/}
              {/*  <span class="front text">换一个问题</span>*/}
              {/*</button>*/}
              {/*<button onClick={runTask}>*/}
              {/*  <span class="edge"></span>*/}
              {/*  <span class="front text">跳过，立即搜索</span>*/}
              {/*</button>*/}
            </div>
          </div>
        </div>
      )}

      {waitingMessage && (
        <div className="coach-message-container">
          <div className="coach-message">
            <div className="agentOutput-waiting-message">
              <span>{waitingMessage}</span>
              {waitingMessageProgress && (
                <div className="waiting-message-loader">
                  <div className="progress" style={{ width: waitingMessageProgress }}></div>
                </div>
              )}
            </div>
            <img className="coach-message-top-icon" src="/icon/coachMessageTop.svg" />
            <img className="coach-message-bottom-icon" src="/icon/coachMessageBottom.svg" />
          </div>
        </div>
      )}

      <Canvas
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          zIndex: 1,
        }}
        shadows
        camera={{ fov: 25, near: 1, far: 1000000, position: [0, 3, 0], zoom: 0.3 }}>
         <fog attach="fog" args={['#16a04b', 12, 30]}></fog>
        <EffectComposer>
          <Bloom mimapBlur luminanceThreshold={1} intensity={1.42} radius={0.72} />
        </EffectComposer>
        <Coach currentAction={currentAction} loaded={loaded} />
        <Stage
          loaded={loaded}
          showChatButton={async () => {
            setShowChatButton(true);
            await sleep(500);
            setShowRecordsButton(true);
          }}
          chatMode={chatMode}
        />
      </Canvas>
      <Loading/>

    </>
  );
}

export default App;
